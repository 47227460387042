<template>
  <div
    class="main_content program_emp_search custom_search align-items-center"
    style="min-height: 80vh"
  >
    
    <div class="row justify-content-between mb_20">
      <div class="col-12 col-md-12 col-xl-5 col-xxl-5">
        <div class="heading padd_ipad">
          <h2 class="Employee_head">Custom Search</h2>
          <h3 class="Program_dashboard_head">Program Manager Dashboard</h3>
        </div>
      </div>

      <div class="col-12 col-md-12 col-xl-7 col-xxl-7">
        <div class="align-items-center d-flex justify-content-end w-100">
          <div class="date_toolbar_main">
            <div class="bg-white date-toolbar">
              <div class="row me-0">
                <div class="col-xs-12 col-md-12 col-lg-12 align-self-center">
                  <div class="">
                    <div class="bg-white date-toolbar" style="box-shadow: none">
                      <div class="row">
                        <div class="col-xs-12 col-md-12 align-self-center">
                          <div class="row">
                            <div class="col-xs-12 col-md-12 col-lg-6">
                              <div class="date">
                                <div class="heading_5 Top_date">From</div>
                                <div
                                  class="text_black font_size_14 d-flex align-items-center"
                                >
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="17.5"
                                      height="17.5"
                                      viewBox="0 0 17.5 17.5"
                                    >
                                      <path
                                        data-name="Path 131"
                                        d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                        transform="translate(-2.25 -2.25)"
                                        style="
                                          fill: none;
                                          stroke-linecap: round;
                                          stroke-linejoin: round;
                                          stroke-width: 1.5px;
                                        "
                                      />
                                    </svg>
                                  </span>
                                  <datepicker
                                    placeholder="MM DD, YYYY"
                                    v-model="listConfig.start_date"
                                    :format="customFormatter"
                                    @selected="changeStartDate"
                                    :disabledDates="disabledFutureDates"
                                  ></datepicker>
                                  <p class="min-date-value d-none">
                                    {{ listConfig.start_date | formatDate }}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="col-xs-12 col-md-12 col-lg-6">
                              <div class="date todate pl_6 border-0">
                                <div class="heading_5 Top_date">To</div>
                                <div
                                  class="text_black font_size_14 d-flex align-items-center"
                                >
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="17.5"
                                      height="17.5"
                                      viewBox="0 0 17.5 17.5"
                                    >
                                      <path
                                        data-name="Path 131"
                                        d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                        transform="translate(-2.25 -2.25)"
                                        style="
                                          fill: none;
                                          stroke-linecap: round;
                                          stroke-linejoin: round;
                                          stroke-width: 1.5px;
                                        "
                                      />
                                    </svg>
                                  </span>
                                  <datepicker
                                    placeholder="MM DD, YYYY"
                                    v-model="listConfig.end_date"
                                    :format="customFormatter"
                                    @selected="changeEndDate"
                                    :disabled="isDisabled"
                                    :disabledDates="disabledDates"
                                  ></datepicker>
                                  <p class="max-date-value d-none">
                                    {{ listConfig.end_date | formatDate }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a href="javascript:" class="reset_btn" @click="resetAll">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 1000 1000"
              enable-background="new 0 0 1000 1000"
              xml:space="preserve"
            >
              <metadata>
                Svg Vector Icons : http://www.onlinewebfonts.com/icon
              </metadata>
              <g>
                <path
                  d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z"
                />
              </g>
            </svg>
          </a>
        </div>
      </div>
    </div>
    <div class="row justify-content-between mb_20">
      <div class="col-12 col-md-12">
        <div class="tab_report toggle_switch list_card_switch me-0 my-2">
          <ul id="myTab" role="tablist" class="nav nav-tabs list_tabs">
            <li role="presentation" class="nav-item list-item">
              <router-link
                :class="
                  $route.name == 'custom_search_self_assessment'
                    ? 'nav-link list-link active'
                    : 'nav-link list-link'
                "
                :to="{ name: 'custom_search_self_assessment' }"
                id="sa-tab"
                aria-controls=""
                aria-selected="true"
              >
                Self-Assessment
              </router-link>
            </li>
            <li role="presentation" class="nav-item list-item">
              <router-link
                :class="
                  $route.name == 'custom_search_ergo_eval'
                    ? 'nav-link list-link active'
                    : 'nav-link list-link'
                "
                :to="{ name: 'custom_search_ergo_eval' }"
                id="sa-tab"
                aria-controls=""
                aria-selected="true"
              >
                Ergo Evaluation
              </router-link>
            </li>
            <li role="presentation" class="nav-item list-item">
              <router-link
                :class="
                  $route.name == 'custom_search_follow_up'
                    ? 'nav-link list-link active'
                    : 'nav-link list-link'
                "
                :to="{ name: 'custom_search_follow_up' }"
                id="sa-tab"
                aria-controls=""
                aria-selected="true"
              >
                Follow-Up Surveys
              </router-link>
              <!-- <button
                  id="fus-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#"
                  type="button"
                  role="tab"
                  aria-controls=""
                  aria-selected="false"
                  class="nav-link list-link"
                >
                  Follow-Up Surveys
                </button> -->
            </li>
            <li role="presentation" class="nav-item list-item">
              <router-link
                :class="
                  $route.name == 'custom_search_schedule_request'
                    ? 'nav-link list-link active'
                    : 'nav-link list-link'
                "
                :to="{ name: 'custom_search_schedule_request' }"
                id="sa-tab"
                aria-controls=""
                aria-selected="true"
              >
                Schedule Request
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="row mb_20 me-4 status_box new_status status_select i_all_down"
    >
      <div class="mn-20 mb-3">
        <label for="region">Region</label
        ><v-select
          @input="getReportsBysearch"
          v-model="listConfig.region"
          label="name"
          name="region"
          :reduce="(region_list) => region_list"
          :options="region_list"
          class="select vs-drop-cust"
          placeholder="Select"
          v-validate="'required'"
          data-vv-as="region"
        />
      </div>
      <div class="mn-20 mb-3">
        <label for="region">Sub Region</label
        ><v-select
          @input="getReportsBysearch"
          v-model="listConfig.subregion"
          label="subregion"
          name="subregion"
          :reduce="(subregion_list) => subregion_list"
          :options="subregion_list"
          class="select vs-drop-cust"
          placeholder="Select"
          data-vv-as="Subregion"
        />
      </div>
      <div class="mn-20 mb-3">
        <label for="region">Building Location</label
        ><v-select
          @input="getReportsBysearch"
          v-model="listConfig.location"
          label="Location"
          name="Location"
          :reduce="(building_location) => building_location"
          :options="building_location"
          class="select vs-drop-cust"
          placeholder="Select"
          data-vv-as="Location"
        />
      </div>
      <div class="mn-20 mb-3">
        <label for="region">Work Area Assessed</label
        ><v-select
          @input="getReportsBysearch"
          v-model="listConfig.work_area_assessed"
          label="work_area_assessed"
          name="work_area_assessed"
          :reduce="(work_area_assessed) => work_area_assessed"
          :options="work_area_assessed"
          class="select vs-drop-cust"
          placeholder="Select"
          data-vv-as="work_area_assessed"
        />
      </div>
      <div class="mn-20 mb-3">
        <label for="region">Reason for Evaluation</label
        ><v-select
          @input="getReportsBysearch"
          v-model="listConfig.reason_for_eval"
          label="Reason for Evaluation"
          name="reason_for_eval"
          :reduce="(reason_for_eval) => reason_for_eval"
          :options="reason_for_eval"
          class="select vs-drop-cust"
          placeholder="Select"
        />
      </div>
      <div class="mn-20 mb-3">
        <label for="region">Self-Reported Discomfort</label
        ><v-select
          @input="getReportsBysearch"
          v-model="listConfig.self_reported_discomfort"
          label="Self-Reported Discomfort"
          name="self_reported_discomfort"
          :reduce="(self_reported_discomfort) => self_reported_discomfort"
          :options="self_reported_discomfort"
          class="select vs-drop-cust"
          placeholder="Select"
        />
      </div>
      <div class="mn-20 mb-3">
        <label for="region">Discomfort Levels</label
        ><v-select
          @input="getReportsBysearch"
          v-model="listConfig.discomfort_levels"
          label="Discomfort Levels"
          name="discomfort_levels"
          :reduce="(discomfort_levels) => discomfort_levels"
          :options="discomfort_levels"
          class="select vs-drop-cust"
          placeholder="Select"
        />
      </div>
      <div class="mn-20 mb-3">
        <label for="region">Equipment Recommended</label
        ><v-select
          @input="getReportsBysearch"
          v-model="listConfig.equipment_recommendation"
          label="Equipment Recommended"
          name="equipment_recommendation"
          :reduce="(equipment_recommendation) => equipment_recommendation"
          :options="equipment_recommendation"
          class="select vs-drop-cust"
          placeholder="Select"
        />
      </div>
       <div class="mn-20 mb-3">
        <label for="region">Department</label
        ><v-select
          @input="getReportsBysearch"
          v-model="listConfig.department"
          label="Department"
          name="department"
          :reduce="(department) => department"
          :options="department"
          class="select vs-drop-cust"
          placeholder="Select"
        />
      </div>
      <div class="mn-20 mb-3">
        <label for="region">WFH Safety</label
        ><v-select
          @input="getReportsBysearch"
          v-model="listConfig.wfh_saftey"
          label="wfh_saftey"
          name="wfh_saftey"
          :reduce="(wfh_saftey) => wfh_saftey"
          :options="wfh_saftey"
          class="select vs-drop-cust"
          placeholder="Select"
        />
      </div>
      <!-- <div class="mn-20 mb-3">
        <label for="region">All Other Questions</label
        ><v-select
          @input="getReportsBysearch"
          v-model="listConfig.other_questions"
          label="Other Questions"
          name="other_questions"
          :reduce="(other_questions) => other_questions"
          :options="other_questions"
          class="select vs-drop-cust"
          placeholder="Other Questions"
        />
      </div> -->
    </div>
    <!-- <div class="table_card"> -->
    <div class="row dataTables_wrapper no-footer" id="datatable_wrapper">
      <div
        class="col-xl-3 col-lg-3 col-md-12 col-xs-12 text-start text-md-start text-lg-start align-self-center my-2 mb-2 mb-lg-5"
      >
        <div
          class="dataTables_length float_none text-center text-lg-start"
          id="datatable_length"
        >
          <label
            >Show
            <!-- @change="getEmployeesBysearch" -->
            <select
              name="datatable_length"
              aria-controls="datatable"
              class=""
              v-model.number="itemsPerPage"
              @change="getReportsBysearch"
            >
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
              <option>500</option>
            </select>
            entries</label
          >
        </div>
      </div>
      <div class="col-xl-9 col-lg-9 col-md-12 col-xs-12">
        <div
          class="d-flex justify-content-lg-end justify-content-center align-items-center flex-wrap mb-4 mb-lg-4"
          id="mobileCenter"
        >
          <!-- v-if="!reviewCheckbox.length" -->
          <div
            id="datatable_filter"
            class="dataTables_filter my-2"
            v-if="!searchCheckbox.length"
          >
            <label class="mb-0"
              ><input
                type="search"
                class=""
                placeholder="Search"
                aria-controls="datatable"
                v-model="listConfig.search_string"
                v-on:keyup.enter="getReportsBysearch"
              />
              <a
                v-if="listConfig.search_string"
                @click="getReportsBysearch"
                href="javascript:void(0)"
                class="search_icon icon_level"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  fill="#7d8d9e"
                  class="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                  />
                </svg>
              </a>
              <a
                href="javascript:void(0)"
                v-if="listConfig.search_string"
                @click="
                  listConfig.search_string = null;
                  getReportsBysearch();
                "
                class="crossicon"
              >
                X
              </a>
            </label>
          </div>
          <div class="table_search ergo_eval_table" v-if="search_data.length">
            <div class="d-flex align-items-center">
                <a href="javascript:" class="ml_12 xl_download mt-0" @click="exportAllData()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                      <g data-name="Group 12689">
                        <path data-name="Path 114" d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                          transform="translate(-3 -3)"
                          style="
                            fill: none;
                            stroke: #1a191c;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 2px;
                          "
                        />
                      </g>
                    </svg>
                    Download All
                  </a> 
            </div>
          </div>
          <!-- <a
            href="javascript:"
            class="ml_12 xl_download"
            v-if="searchCheckbox.length"
            @click="exportTableData()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
            >
              <g data-name="Group 12689">
                <path
                  data-name="Path 114"
                  d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                  transform="translate(-3 -3)"
                  style="
                    fill: none;
                    stroke: #a9a9a9;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 2px;
                  "
                />
              </g>
            </svg>
            Download
          </a> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div id="myTab2Content" class="tab-content">
          <div
            id="percent"
            role="tabpanel"
            aria-labelledby="#percent"
            class="tab-pane fade active show"
          >
            <div class="table_card all_user">
              <div
                class="table-responsive table_audit_log report_table emp_table"
              >
                <table
                  align="justify"
                  class="table table-bordered table dataTable no-footer"
                >
                  <thead>
                    <tr>
                      <!-- <th style="min-width: 10px" class="sort sml_check">
                        <div
                          class="form_check_box d-flex align-items-center mt-1"
                        >
                          <input
                            type="checkbox"
                            v-model="selectAll"
                            aria-label="..."
                            id="flexCheckDefault"
                            class="form-check-input ms-0"
                            @change="checkSelected($event)"
                          />
                          <label for="flexCheckDefault" class=""> </label>
                        </div>
                      </th> -->
                      <th align="center" class="sort sml_n_last_first">
                        <SortIcons
                          :lable="'First Name'"
                          :listConfig="listConfig"
                          :sortBy="'first_name'"
                          @sorting="sorting($event)"
                        />
                      </th>
                      <th align="center" class="sort sml_n_last_first">
                        <SortIcons
                          :lable="'Last Name'"
                          :listConfig="listConfig"
                          :sortBy="'last_name'"
                          @sorting="sorting($event)"
                        />
                      </th>
                      <th align="center" class="sort">
                        <SortIcons
                          :lable="'Email Address'"
                          :listConfig="listConfig"
                          :sortBy="'user_email'"
                          @sorting="sorting($event)"
                        />
                      </th>
                      <th align="center" class="sort sml_region">
                        <SortIcons
                          :lable="'Region'"
                          :listConfig="listConfig"
                          :sortBy="'region'"
                          @sorting="sorting($event)"
                        />
                      </th>
                      <th
                        align="center"
                        style="text-align: center"
                        scope="col"
                        class="sml_region"
                      >
                        Activity
                        <label class="form-label"><svg  v-tooltip=" 'Filtered data visible under Activity' " xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16" viewBox="0 0 14 18.346" ><path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none" ></path><path data-name="Path 9427" d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z" transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)" ></path></svg></label>
                      </th>

                      <th
                        align="center"
                        style="text-align: center"
                        scope="col"
                        class="sml_region"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- Main Loop -->
                    <tr v-for="(search, indx) in search_data" :key="indx">
                      <!-- <td style="min-width: 10px">
                        <div class="form_check_box d-flex align-items-center">
                          <input
                            type="checkbox"
                            v-model="searchCheckbox"
                            :value="search.id"
                            aria-label="..."
                            :id="'flexCheckDefault' + indx"
                            class="form-check-input ms-0"
                            @change="checkSelected($event)"
                          />
                          <label :for="'flexCheckDefault' + indx" class="">
                          </label>
                        </div>
                      </td> -->
                      <td>
                        {{ search.first_name | capitalize }}
                      </td>
                      <td>
                        <p>{{ search.last_name ? capitalize(search.last_name) : "NA" }}</p>
                      </td>
                      <td>
                        <p class="email_tag">
                          <a :href="'mailto:' + search.user_email">{{
                            search.user_email
                          }}</a>
                        </p>
                      </td>
                      <td>
                        <p>{{ search.region ? search.region : "NA" }}</p>
                      </td>
                      <td align="center" style="text-align: center">
                        {{ search.recordsCount }}
                      </td>
                      <td align="center" style="text-align: center">
                        <div class="edit_btn">
                          <p>
                            <a
                              href="javascript:void(0)"
                              @click="
                                showFilteredReports(
                                  search.user_uid,
                                  reportIds[search.user_id],
                                  search.enc_client_id
                                )
                              "
                              title="View Reports"
                            >
                              <img
                                :src="
                                  require('@/assets/images/dashboard/View_Icon.svg')
                                "
                                alt="View Reports"
                              />
                            </a>
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="data_length == 0">
                      <td colspan="6" class="text-center">No Records found</td>
                    </tr>
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </div>
          </div>
          <div
            id="hash"
            role="tabpanel"
            aria-labelledby="#hash"
            class="tab-pane fade"
          >
            <div class="Employee_search mt-2">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3">
                  <div class="white_card_box position-relative">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        aria-label="..."
                        class="form-check-input ms-0"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12">
                <h3
                  class="text-center no_data card_no_data white_card border_radius_20"
                >
                  No Records found
                </h3>
              </div>
            </div>
          </div>
          <div class="flex-wrap mt-4">
            <p class="float-lg-start text-sm-center">{{ positionText }}</p>
            <VPagination
              v-if="data_length"
              class="dataTables_paginate"
              :totalPages="pages.length"
              :perPage="itemsPerPage"
              :currentPage.sync="currentPage"
              @pagechanged="onPageChange"
            />
          </div>
        </div>
      </div>
    </div>
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import $ from "jquery";
import commonFunction from "../../../mixin/commonFunction";
import VPagination from "@/components/VPagination";
import SortIcons from "@/components/SortingIcons";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import exportFromJSON from "export-from-json";
import ConfirmDialogue from "@/components/ConfirmDialogue";

export default {
  name: "CustomSearchErgoEval",
  mixins: [commonFunction],
  components: {
    VPagination,
    SortIcons,
    Datepicker,
    ConfirmDialogue
  },
  data() {
    return {
      show: false,
      isLoader: false,
      currentPage: 1,
      itemsPerPage: 10,
      region_list: [],
      subregion_list: [],
      building_location: [],
      // completed_self_assessment: [],
      work_area_assessed: [],
      reason_for_eval: [],
      self_reported_discomfort: [],
      discomfort_levels: [],
      cause_of_discomfort: [],
      equipment_recommendation: [],
      department:[],
      wfh_saftey:[],
      other_questions: [],
      search_data: [],
      searchCheckbox: [],
      listConfig: {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        order: "ASC",
        sortBy: "id",
        search_string: null,
        start_date: null,
        end_date: null,
        search_status: null,
        region: null,
        subregion: null,
        location: null,
        // completed_self_assessment: null,
        work_area_assessed: null,
        reason_for_eval: null,
        self_reported_discomfort: null,
        discomfort_levels: null,
        cause_of_discomfort: null,
        equipment_recommendation: null,
        department:null,
        wfh_saftey:null,
        saftey_question_id:null,
        other_questions: null,
        survey_id: null,
        type_of_assessment: "ergo-eval",
      },
      clearButton: true,
      disabledFutureDates: {
        from: new Date(Date.now()),
      },
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      isCheckAll: false,
      isDisabled: "",
      data_length: 0,
      reportIds: [],
      previousStartDate: null,
    };
  },
  computed: {
    selectAll: {
      get: function () {
        return this.search_data
          ? this.searchCheckbox.length == this.search_data.length
          : false;
      },
      set: function (value) {
        var searchCheckbox = [];
        if (value) {
          this.search_data.forEach(function (data) {
            searchCheckbox.push(data.id);
          });
        }
        this.searchCheckbox = searchCheckbox;
      },
    },
    positionText: function () {
      var endIndex = this.currentPage * this.itemsPerPage,
        startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
      if (this.data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.data_length ? this.data_length : endIndex) +
        " of " +
        this.data_length
      );
    },
    btnText: function () {
      if (this.show) {
        return "";
      }
      return "";
    },
    pages() {
      if (this.data_length <= this.itemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
      this.filterSearchData();
    },
  },
  mounted() {
    //Get all regions from Client data...
    (this.listConfig = {
      offset: (this.currentPage - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
      order: "ASC",
      sortBy: "created_at",
      search_string: null,
      start_date: null,
      end_date: null,
      search_status: null,
      region: null,
      subregion: null,
      location: null,
      // completed_self_assessment: null,
      work_area_assessed: null,
      reason_for_eval: null,
      self_reported_discomfort: null,
      discomfort_levels: null,
      cause_of_discomfort: null,
      equipment_recommendation: null,
      department:null,
      wfh_saftey:null,
      saftey_question_id:null,
      other_questions: null,
      survey_id: null,
      type_of_assessment: "ergo-eval",
      // searchCheckbox: [],
    }),
      (document.title =
        "PBErgo - " + this.$router.currentRoute.meta.page_title);
    this.listSurveyOption();
    this.filterSearchData();
  },
  methods: {
    capitalize(value) {
      if (!value) return 'NA';
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    async exportAllData() {
      const confirmDownload = await this.$refs.confirmDialogue.show({
        title: "Download",
        message: "Are you sure you want to download?",
        okButton: "Download",
      });

      if (!confirmDownload || !localStorage.getItem("userToken")) return;

      this.$parent.$refs.fullpageloader.is_full_page_loader = true;

      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API_URL}/api/custom-search-download-all`,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { request_data: this.encodeAPIRequestWithImages(this.listConfig) },
      };

      try {
        const { data } = await this.axios(config);
        this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        const downloadResponse = this.decodeAPIResponse(data.data);
        
        if (!downloadResponse) return;
        // console.log(downloadResponse);
        const filterExport = downloadResponse.map(responseData => ({
          "First Name": this.capitalize(responseData.first_name),
          "Last Name": this.capitalize(responseData.last_name),
          "Email": responseData.user_email,
          "Region": responseData.region ? responseData.region : "NA",
          "Ergo Eval Activity":  responseData.recordsCount ? responseData.recordsCount : "NA",
        }));

        const fileName = "ErgoEvalSearchList";
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data: filterExport, fileName, exportType });

        // Audit log for download
        const auditLogConfig = {
          method: "post",
          url: `${process.env.VUE_APP_API_URL}/api/audit-log-for-download`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: this.encodeAPIRequestWithImages({ section: 'Custom Search Ergo Eval', dashboard: 'Custom Search' }),
        };

        try {
          const auditLogResponse = await this.axios(auditLogConfig);
          console.log(auditLogResponse.data.message);
        } catch (auditLogError) {
          if (auditLogError.response.status === 401) {
            this.tokenExpired();
          }
        }
      } catch (error) {
        this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        this.$toast.error(error.response.data.error, {
          position: "top-right",
          duration: 5000,
        });
        if (error.response.status === 401) {
          this.$router.push({ name: "login" });
        }
      }
    },
    // exportTableData() {
    //   var filterExport = [];
    //   var _this = this;
    //   var data;
    //   if (_this.searchCheckbox.length) {
    //     _this.search_data.forEach(function (responseData) {
    //       if (_this.searchCheckbox.includes(responseData.id)) {
    //         filterExport.push({
    //           "First Name": responseData.first_name,
    //           "Last Name": responseData.last_name,
    //           Email: responseData.user_email,
    //           Region: responseData.location,
    //           "Ergo Eval Activity": responseData.recordsCount,
    //         });
    //       }
    //     });
    //     data = filterExport;
    //   } else {
    //     data = this.exportData; // exportData is calling from Laravel Controller
    //   }
    //   const fileName = "ErgoEvalSearchList";
    //   const exportType = exportFromJSON.types.csv;
    //   if (data) exportFromJSON({ data, fileName, exportType });
    // },
    checkSelected(event) {
      if (event.target.checked) {
        document.getElementById("mobileCenter").classList.add("mobile-center");
      } else {
        if (this.searchCheckbox.length == 0) {
          document
            .getElementById("mobileCenter")
            .classList.remove("mobile-center");
        }
      }
    },
    showFilteredReports(userId, reportIds, clientId) {
      window.localStorage.setItem("reportIds_ee" + userId, reportIds);
      this.$router.push({
        name: "search_survey_reports_EE",
        params: { id: userId, clientId: clientId },
      });
    },
    listSurveyOption() {
      var _this = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/list-survey-option/ergo-eval",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          var filterData = _this.decodeAPIResponse(data.records.data);
          if (filterData) {
            _this.region_list = filterData.Location1; // Location1
            _this.subregion_list = filterData.Location2; // SubRegion
            _this.building_location = filterData.Location3; // BuildingLocation
            // _this.completed_self_assessment =
            //   data.records.completed_self_assessment;
            _this.work_area_assessed = filterData.work_area_assessed;
            _this.reason_for_eval = filterData.reason_for_eval;
            _this.self_reported_discomfort =
              filterData.self_reported_discomfort;
            _this.discomfort_levels = filterData.discomfort_Levels;
            // _this.cause_of_discomfort = data.records.discomfort_casuse;
            _this.equipment_recommendation = filterData.equip_recommedation;
            _this.department = filterData.Department;
            _this.wfh_saftey = filterData.wfh_saftey;
            _this.listConfig.saftey_question_id = filterData.saftey_question_id;
           

            // _this.other_questions = data.records.other_questions;

            // _this.listConfig.survey_id = data.records.survey_id;
          }
        })
        .catch(({ response }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          _this.$toast.error(response.data.error, {
            position: "top-right",
            duration: 5000,
          });
          if (response.status == 403) {
            this.$router.push({ name: "403" });
          }
        });
    },

    filterSearchData() {
      this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      var _this = this;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/custom-search",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: {request_data : this.encodeAPIRequestWithImages(_this.listConfig)},
      };
      _this
        .axios(config)
        .then(({ data }) => {
          // var test = this.encodeAPIRequest(_this.listConfig);
          // console.log(_this.decodeAPIResponse(test));
          var searchedData = _this.decodeAPIResponse(data.records.data);
          if (searchedData) {
            // console.log(data.allResponses);
            _this.search_data = searchedData.allResponses;
            _this.data_length = searchedData.counts;
            _this.reportIds = searchedData.reportIds;
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          }
        })
        .catch(({ response }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          _this.$toast.error(response.data.error, {
            position: "top-right",
            duration: 5000,
          });
        });
    },

    /** Export Csv File based on selected checkboxes */
    resetAll() {
      this.listConfig.end_date = "";
      this.listConfig.start_date = "";
      this.isDisabled = true;
      this.listConfig.region = null;
      this.listConfig.subregion = null;
      this.listConfig.location = null;
      // this.listConfig.completed_self_assessment =  null;
      this.listConfig.work_area_assessed = null;
      this.listConfig.reason_for_eval = null;
      this.listConfig.self_reported_discomfort = null;
      this.listConfig.cause_of_discomfort = null;
      this.listConfig.equipment_recommendation = null;
      this.listConfig.department = null;
      this.listConfig.wfh_saftey = null;
      this.listConfig.other_questions = null;
      this.listConfig.discomfort_levels = null;
      this.filterSearchData();
    },
    selectSearchDate() {
      this.$nextTick(() => {
        this.getReportsBysearch();
      });
    },
    showDatePicker() {
      this.$refs.dp2.showCalendar();
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    sorting($event) {
      this.listConfig.sortBy == $event.sortBy;
      this.listConfig.order == $event.order;
      this.filterSearchData();
    },
    getReportsBysearch() {
      this.currentPage = 1;
      this.listConfig.limit = this.itemsPerPage;
      this.filterSearchData();
    },
    updateDatePicker() {
      // Extend dataTables search
      $.fn.dataTable.ext.search.push(function (settings, data) {
        var min = $(".min-date-value").html();
        var max = $(".max-date-value").html();
        var createdAt = data[0] || 0; // Our date column in the table
        if (
          min == "" ||
          max == "" ||
          (moment(createdAt).isSameOrAfter(min) &&
            moment(createdAt).isSameOrBefore(max))
        ) {
          return true;
        }
        return false;
      });
      this.getReportsBysearch();
    },
    // changeStartDate() {
    //   this.$nextTick(() => {
    //     this.isDisabled = false;
    //     this.disabledDates = {
    //       to: new Date(this.listConfig.start_date - 8640000),
    //       from: new Date(Date.now()),
    //     };
    //     if (
    //       this.listConfig.end_date != "" &&
    //       this.listConfig.end_date != null
    //     ) {
    //       this.updateDatePicker();
    //     }
    //   });
    // },
    changeStartDate(newStartDate) {
      this.$nextTick(() => {
        const startDate = new Date(newStartDate);
        const endDate = this.listConfig.end_date ? new Date(this.listConfig.end_date) : null;

        if (endDate && startDate > endDate) {
          this.$toast.error('"From" date cannot be greater than the "To" date.', {
              position: "top-right",
              duration: 5000,
            });
          this.listConfig.start_date = this.previousStartDate;
        } else {
          this.previousStartDate = newStartDate;

          // Old changeStartDate code
          this.isDisabled = false;
          this.disabledDates = {
            to: new Date(this.listConfig.start_date - 8640000),
            from: new Date(Date.now()),
          };
          if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
            this.updateDatePicker();
          }
        }
      });
    },
    changeEndDate() {
      this.$nextTick(() => {
        this.updateDatePicker();
      });
    },
    customFormatter(date) {
      return moment(date).format("MMM DD, YYYY");
    },
  },
};
</script>